import './layout.css'
import React from 'react'
import { Link, navigateTo } from 'gatsby'
import * as storage from '../utils/storage'
import axios from 'axios'
import Layout from '../components/layout'

class CreateLogin extends React.Component {
    state = {
        loading: false,
        email: ``,
        email_confirm: ``,
        password: ``,
        showPassword: false,
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        })
    }

    handleSubmit(event) {
        event.preventDefault()
        for (let key in this.state) {
            if (key === 'loading' || key === 'showPassword') continue
            if(!this.state[key] || this.state[key] == '') {
                alert('Please complete all fields!')
                return;
            }
        }
        if (this.state.email !== this.state.email_confirm) {
            alert('Emails do not match!')
            return;
        }

        this.setState({loading: true})

        // validate email
        axios.post(`${process.env.API_BASE_URL}/api/user/validate-email`, {
                email: this.state.email
            })
            .then(() => {
                storage.setItem('temp-email', this.state.email)
                storage.setItem('temp-password', this.state.password)
                navigateTo('/create-profile')
            })
            .catch(err =>{
                this.setState({loading: false})
                alert('Email already registered, please log in or reset your password')
            })

    }

    handleToggleShowPassword = () => {
        this.setState(state => ({
          showPassword: !state.showPassword
        }))
      }
    

    render() {
        const { showPassword } = this.state
        return (
            <Layout>
                <h2 className="has-text-centered title is-3" style={{ marginTop: '34px' }}>Create Your Login</h2>
                <form method="post" onSubmit={e => this.handleSubmit(e)}>
                    <div className="form-wrapper" style={{maxWidth:'450px',width:'80%'}}>
                        <div className="field">
                            <div className="control">
                                <label>Email*</label>
                                <input className="input" name="email" type="text" onChange={e => this.handleChange(e)} autoComplete="off" />
                            </div>
                        </div>
                        <div className="field">
                            <div className="control">
                                <label>Confirm Email*</label>
                                <input className="input" name="email_confirm" type="text" onChange={e => this.handleChange(e)} onPaste={e => e.preventDefault()} />
                            </div>
                        </div>
                        <div className="field">
                            <div className="control">
                            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                <label>Password*</label>
                                <label onClick={() => this.handleToggleShowPassword() }>{showPassword ? 'Hide' : 'Show'}</label>
                            </div>
                            <input
                                className="input"
                                name="password"
                                type={showPassword ? 'text' : 'password'}
                                onChange={e => this.handleChange(e)}
                            />
                            </div>
                        </div>
                        <div className="fd-center">
                            <button type="submit" className={`button is-medium has-background-link has-text-white is-hidden-mobile ${this.state.loading ? 'is-loading' : ''}`} >Continue</button>
                            <div className="fd-central"><button type="submit" className={`button is-medium has-background-link has-text-white is-hidden-tablet is-fullwidth ${this.state.loading ? 'is-loading' : ''}`} >Continue</button></div>
                        </div>
                        <div className="fd-center" style={{marginBottom:'50px'}}>
                            <p>
                                 Already have a profile? <Link to="/login" className="links">Login</Link>
                            </p>
                        </div>
                    </div>
                </form>
            </Layout>
        )
    }
}

export default CreateLogin
